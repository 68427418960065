import * as React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { SocialIcon } from 'react-social-icons'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

function About({ location }) {
  return (
    <>
      <Meta
        title="About Us"
        description="ProGro Landscapes was founded in 2018 by two friends with a shared dream of using their experience to create a successful landscaping business. Starting out with a clapped out van, and a few hand tools, we built on this to become the company we are today."
        location={location}
      />
      <Layout accent>
        <section className="relative bg-white py-8 sm:py-12">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative sm:py-16 lg:py-0">
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div className="relative rounded-2xl shadow-xl overflow-hidden">
                  <StaticImage
                    src="../images/services/patio-decking-feature-1.webp"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                  About ProGro Landscapes Ltd.
                </h2>
                <div className="mt-6 space-y-6">
                  <p className="text-lg">
                    Welcome to ProGro Landscapes. We offer professional
                    landscaping services to both residential and commercial
                    clients across Berkshire, and Surrey.
                  </p>
                  <p className="text-base leading-7">
                    At ProGro Landscapes, our focus is on customer satisfaction.
                    We aim to build landscapes that last, and client
                    relationships that last.
                  </p>
                  <h2 className="text-xl font-extrabold tracking-tight sm:text-2xl">
                    Our Story
                  </h2>
                  <p className="text-lg">
                    ProGro Landscapes was founded in 2018 by two friends with a
                    shared dream of using their experience to create a
                    successful landscaping business.
                  </p>
                  <p className="text-base leading-7">
                    Starting out with a clapped out van, and a few hand tools,
                    we built on this to become the company we are today. Our
                    focus has always been on our clients&apos; satisfaction.
                    Which is why we now have a glowing and growing reputation as
                    a trusted landscape business that delivers first class
                    results.
                  </p>
                  <p>
                    Our ambition is to become known as the best landscape
                    business in Berkshire, and we will achieve this by
                    continuing to build beautiful spaces that everyone loves.
                  </p>
                </div>
              </div>
              <div className="mt-10">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                  <div className="border-t-2 border-gray pt-6">
                    <dt className="text-base text-gray-light">Founded</dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray">
                      2018
                    </dd>
                  </div>
                  <div className="border-t-2 border-gray pt-6">
                    <dt className="text-base text-gray-light">Projects</dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray">
                      360+
                    </dd>
                  </div>
                  <div className="border-t-2 border-gray pt-6">
                    <dt className="text-base text-gray-light">Seeds Sowed</dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray">
                      400,000+
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>
        <section className="mx-auto py-12 px-4 max-w-7xl bg-white sm:px-6 lg:px-8 lg:py-16">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                Meet Our Team
              </h2>
              <p className="text-xl text-gray-light">
                All directors and senior members hold various qualifications in
                landscaping and have gained Health and Safety accreditation.
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                <li>
                  <div className="space-y-4">
                    <StaticImage
                      className="shadow-lg rounded-lg"
                      src="../images/services/jake-lavender.webp"
                      aspectRatio={3 / 2}
                      transformOptions={{ fit: 'inside' }}
                      placeholder="blurred"
                    />
                    <div className="text-lg leading-6 space-y-1">
                      <h3>Jake Lavender</h3>
                      <p className="text-green">Director</p>
                    </div>
                    <div className="text-lg">
                      <p className="text-gray-light">
                        Jake has given many years in learning the landscaping
                        trade and has worked for both the residential and
                        commercial, and public landscaping sector. He has gained
                        many practical qualifications that allow him to safely
                        work with trees and pesticides for example. Jake has
                        also gained valuable experience in using agricultural
                        equipment for the landscaping trade, asuch as the niche
                        service of Big Roll Turf. Jake Lavender is also our
                        First Aider.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="mx-auto py-12 px-4 max-w-5xl bg-white sm:px-6 lg:px-8 lg:py-16">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            Find us on social media
          </h2>
          <div className="mt-8 flex space-x-6">
            <SocialIcon url="https://www.linkedin.com/company/progro-landscapes" />
            <SocialIcon url="https://www.facebook.com/ProGroLandscapes/" />
            <SocialIcon url="https://instagram.com/progrolandscapes" />
          </div>
        </section>
      </Layout>
    </>
  )
}

About.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default About
